<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab>基本信息</v-tab>
      <v-tab>账号设置</v-tab>
      <v-tab>系统设置</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <info></info>
      </v-tab-item>
      <v-tab-item>
        <accountSettings></accountSettings>
      </v-tab-item>
      <v-tab-item>
        <systemSettings></systemSettings>
      </v-tab-item>
    </v-tabs-items>
  </div>

</template>

<script>
import info from "@/views/person/info";
import accountSettings from "@/views/person/accountSettings";
import systemSettings from "@/views/person/systemSettings";

export default {
  name: "index",
  components: {info, accountSettings,systemSettings},
  data() {
    return {
      tab: null
    }
  }
}
</script>

<style scoped>

</style>