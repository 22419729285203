var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"2","width":"70%"}},[_c('v-card-title',[_vm._v("修改密码")]),_c('v-card-text',{staticClass:"d-flex flex-column align-content-start"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('validation-provider',{attrs:{"name":"旧密码","rules":"required|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"旧密码","outlined":"","dense":"","type":_vm.show ? 'text' : 'password',"error-messages":errors,"required":""},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"新密码","type":"password","rules":{
            regexpass:'^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,16}$',
            required: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{ref:"pass2",attrs:{"label":"新密码","outlined":"","dense":"","error-messages":errors,"required":"","type":_vm.show ? 'text' : 'password'},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"新密码","rules":"required|password:@新密码"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"再次输入新密码","outlined":"","dense":"","error-messages":errors,"required":"","type":_vm.show ? 'text' : 'password'},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)}),_c('v-switch',{attrs:{"label":"显示密码"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.changePassword}},[_vm._v("修改密码")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }