<template>
  <v-card width="400" class="ma-4 elevation-0">
    <div>{{ img }}</div>
    <v-card-text>

      <v-badge bordered bottom overlap offset-x="20" offset-y="20" class="mb-8" for="avatar2">
        <template v-slot:badge>
          <v-icon>
            mdi-camera
          </v-icon>
        </template>
        <v-file-input truncate-length="15" v-show="false" id="avatar_uploader" accept="image/*" @change="selected"
                      v-model="img"></v-file-input>
        <label for="avatar_uploader">
          <v-avatar class="white--text font-weight-bold mx-2" color="primary white--text" size="56">
            <img v-if="user.avatarUrl" :src="user.avatarUrl">
            <div v-else> {{ user.nickName.substring(0, 2) }}</div>
          </v-avatar>
        </label>
      </v-badge>

      <v-text-field readonly dense label="昵称" v-model="user.nickName" outlined></v-text-field>

      <v-radio-group dense v-model="user.gender" row label="性别">
        <v-radio label="男" value="MALE"></v-radio>
        <v-radio label="女" value="FEMALE"></v-radio>
      </v-radio-group>

    </v-card-text>
    <v-card-actions>
      <v-btn :disabled="disabled" :loading="loading" class="mt-4 primary" @click="save">保存</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import cloudbase from "@/plugins/cloudbase";

export default {
  name: "info",
  data() {
    return {
      loading: false,
      disabled: true,
      user: null,
      img: null
    }
  },
  created() {
    let {nickName, gender, avatarUrl} = this.$store.state.user
    this.user = {nickName, gender, avatarUrl}
    this.disabled = true
  },
  methods: {
    save() {
      this.loading = true
      this.uploadAvatar()
          .then(() => {
            return cloudbase.auth().currentUser.update(this.user)
          })
          .then(() => {
            this.loading = false
            this.disabled = true
          })
    },
    selected() {
      this.user.avatarUrl = URL.createObjectURL(this.img)
    },
    uploadAvatar() {
      return new Promise((resolve) => {
        if (this.img) {
          return cloudbase.uploadFile({
            cloudPath: `user/${this.$store.state.user.uid}/avatar`,
            filePath: this.img
          }).then(s => {
            this.user.avatarUrl = s.download_url
          }).then(resolve)
        }
        resolve()
      })
    },
  },
  watch: {
    user: {
      handler(newValue, oldValue) {
        if (oldValue) {
          this.disabled = false
        }
      },
      deep: true
    },
    img() {
      this.disabled = false
    }
  }

}
</script>

<style scoped>

</style>