<template>
  <v-container>
    <changePassword width="65%"></changePassword>
  </v-container>
</template>

<script>

import changePassword from "@/views/person/changePassword";


export default {
  name: "settings",
  components:{changePassword},
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>