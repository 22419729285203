<template>
  <v-card
      elevation="2"
      width="70%"
  >
    <v-card-title>修改密码</v-card-title>
    <v-card-text class="d-flex flex-column align-content-start">
      <validation-observer
          ref="observer"
          v-slot="{invalid}"
      >
        <form>
          <validation-provider
              v-slot="{ errors }"
              name="旧密码"
              rules="required|max:10"
          >
            <v-text-field
                label="旧密码"
                outlined
                dense
                :type="show ? 'text' : 'password'"
                v-model="oldPassword"
                :error-messages="errors"
                required
            ></v-text-field>
          </validation-provider>

          <validation-provider
              v-slot="{ errors }"
              name="新密码"
              type="password"
              :rules="{
              regexpass:'^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,16}$',
              required: true,
            }">
            <v-text-field
                label="新密码"
                outlined
                dense
                ref="pass2"
                v-model="newPassword"
                :error-messages="errors"
                required
                :type="show ? 'text' : 'password'"
            ></v-text-field>
          </validation-provider>
          <validation-provider
              v-slot="{ errors }"
              name="新密码"
              rules="required|password:@新密码">
            <v-text-field
                label="再次输入新密码"
                outlined
                dense
                v-model="confirmPassword"
                :error-messages="errors"
                required
                :type="show ? 'text' : 'password'"
            ></v-text-field>
          </validation-provider>
          <v-switch
              v-model="show"
              label="显示密码"
          ></v-switch>
        </form>

        <v-card-actions>
          <v-btn @click="changePassword" color="primary" :disabled="invalid">修改密码</v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>

import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {required, regex,max} from 'vee-validate/dist/rules'
import cloudbase from "@/plugins/cloudbase";

extend('required', {
  ...required,
  message: '请输入{_field_}',
})

extend('regexpass', {
  ...regex,
  message: '请确保8-16位密码,必须包含数字及大小写字母',
})
extend('max', {
  ...max,
  message: 'len max rule',
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: '两次输入密码不一致'
});
export default {
  name: "changePassword",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      show:false
    }
  },
  methods: {
    changePassword() {
      cloudbase.auth().currentUser.updatePassword(this.newPassword,this.oldPassword)
          .then(s=>{
            console.log(s)
          })
    },
  }
}
</script>

<style scoped>

</style>